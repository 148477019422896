import React, { useState } from 'react'
import { CopyToClipboard } from "react-copy-to-clipboard";
import bannerImg from '../images/banner.png';
import sideImg from '../images/side-img.png';
import work from '../images/work.png';
import copyImg from '../images/copy.png';
import { Link } from 'react-router-dom';


export default function Home() {
    const [addrcopy, setAddrcopy] = useState(false);

    return (
        <>
            <div id="hero-section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="mb-4">
                                <h2>MCASH Mining make passive income long term. withdraw anytime</h2>
                            </div>
                            <p>
                                Earn passive income from mining  Mcash up to 30 years. With the best DAPP Platform. Withdraw your rewards at any time. Now it's time to enjoy your life and finally let your money work for you every day
                            </p>
                            <div className="d-flex gap-2 gap-sm-4 mb-5 mb-md-0">
                                <a href="https://mgpswap.com/swap/" target="_blank" rel="noreferrer" className="cs-btn cs-btn2">
                                    Buy Token &nbsp;&nbsp;<i className="fa-solid fa-arrow-right ml-2"></i>
                                </a>
                                <Link to="/mining" className="cs-btn">
                                    Mining Now&nbsp;&nbsp;<i className="fa-solid fa-arrow-right ml-2"></i>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-6 d-flex justify-content-center">
                            <div className="hero-img">
                                {/* <YouTube videoId="6p7NJl0Ozu4" />
                                {/* <video width="320" height="240" controls>
                                    <source src="https://www.youtube.com/watch?v=6p7NJl0Ozu4" type="video/mp4" />
                                </video> */}
                                <img src={bannerImg} className="lazy img-fluid" alt="pattern" style={{ "height": "350px", "width": "350px" }} />
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div id="token-section" className="py-4 py-sm-5">
                <div className="container">
                    <div className='token-box'>
                        <div className="row">
                            <div className="col-12 d-flex justify-content-center">
                                <div className=''>
                                    <div className="fs-28 mb-4">
                                        MMS CASH (MCASH) Token Contract BSC
                                    </div>
                                    <div className="token-copy d-flex align-items-center overflow-hidden">
                                        <input id="token" type="text" className="form-control border-0 px-4" value="0x154d91190b442f6eF880ED1bAEF6bd04E34659C2" />
                                        <CopyToClipboard text="0x154d91190b442f6eF880ED1bAEF6bd04E34659C2" onCopy={() => {
                                            setAddrcopy(true);
                                            setTimeout(() => {
                                                setAddrcopy(false);
                                            }, 2000)
                                        }}>
                                            <div type="button"
                                                className="align-items-center bg-green d-flex flex-shrink-0 fs-2 h-100 img justify-content-center text-white">
                                                <img className="img-fluid scale" src={copyImg} alt="" />
                                                {addrcopy && <small className="text-center">copied</small>}
                                            </div>

                                        </CopyToClipboard>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="about-section" className='py-4 py-sm-5 mt-5'>
                <div className="container">
                    <div className='row'>
                        <div className='col-sm-12 col-md-12 col-lg-6'>
                            <h1 className='head-title'>MCASH Mining</h1>
                            <p className='text-dark'>Increase your crypto holdings by participating in decentralized networks.</p>

                            <div className="work-wrapper mt-5">
                                <h3><strong>1. Buy tokens on exchanges</strong></h3>
                                <p>Connect wallet and buy token on exchange recieve MCASH automatic</p>
                                <h3><strong>2. Mining on platform</strong></h3>
                                <p>Go to DAPP platform. First connect & confirm wallet, Activate 4,000 MCASH. Confirm mining</p>
                                <h3><strong>3. Enjoy regular long term reward</strong></h3>
                                <p>You rewards are generated daily until the date mining. Now it's time to enjoy your life and finally let your money work for you every day.</p>

                            </div>
                        </div>
                        <div className='col-sm-12 col-md-12 col-lg-6 d-flex justify-content-center'>
                            <img src={sideImg} className="lazy img-fluid" alt="pattern" style={{ "height": "400px", "width": "400px" }} />
                        </div>
                    </div>
                </div>
            </div>
            <div id="work-section" className='py-4 py-sm-5'>
                <div className="container">
                    <div className='row'>
                        <div className='col-sm-12 col-md-12 col-lg-6 d-flex justify-content-start'>
                            <img src={work} className="lazy img-fluid" alt="pattern" style={{ "height": "400px", "width": "400px" }} />
                        </div>
                        <div className='col-sm-12 col-md-12 col-lg-6'>
                            <h1 className='head-title'>Earn Reward Long Time</h1>

                            <div className="work-wrapper mt-5">
                                <h3><strong>1. Activate Mcash on mining platform</strong></h3>
                                <p>Select GN Forever plan. Connect wallet active 20 Mcash</p>
                                <h3><strong>2. Receive reward every day </strong></h3>
                                <p>Reward growth every minute in balance. Withdraw reward anytime auto receive in your wallet</p>
                                <h3><strong>3. Long term reward</strong></h3>
                                <p>Now it’s time to enjoy your life and finally let your money work for you every day long time more than 30 years</p>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="faq-section py-4 py-sm-5">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="fs-37">
                                Frequently Asked Questions
                            </div>
                            <div className="faq mb-5">
                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingOne">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                1. What's do you charge for mining?
                                            </button>
                                        </h2>
                                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body body-text pe-4">
                                                Withdraw fees charge per transaction 20 Mcash
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingTwo">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                2. What is GN Starter?
                                            </button>
                                        </h2>
                                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body body-text pe-4">
                                                GN Starter is first mining on MGP Platform plan. Member can deposit 4,000 Mcash for mining. after 365 days can withdraw
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="heading21">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapse21" aria-expanded="false" aria-controls="collapse21">
                                                3. What is GN Forever?
                                            </button>
                                        </h2>
                                        <div id="collapse21" className="accordion-collapse collapse" aria-labelledby="heading21"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body body-text pe-4">
                                                GN Forever is long time mcash mining on MGP Platform. After mining first plan member deposit 20 Mcash on GN Forever reward Mcash long time 30 years
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingThree">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseThree" aria-expanded="false"
                                                aria-controls="collapseThree">
                                                4. When can I withdraw Mcash?
                                            </button>
                                        </h2>
                                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body body-text pe-4">
                                                GN Starter withdraw after 365 days. GN Forever withdraw anytime
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingFour">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseFour" aria-expanded="false"
                                                aria-controls="collapseFour">
                                                5. Where can I buy Mcash?
                                            </button>
                                        </h2>
                                        <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body body-text pe-4">
                                                Member can trade Mcash on Dex exchange and Central exchange.
                                                Link of DEX exchange.
                                                <a href='https://app.dodoex.io/?from=BNB&to=MCASH' target='_blank' rel="noreferrer">https://app.dodoex.io/?from=BNB&to=MCASH</a>
                                                Link of CEX exchange.
                                                <a href='https://vindax.com/exchange-base.html?symbol=MCASH_USDT' target='_blank' rel="noreferrer">https://vindax.com/exchange-base.html?symbol=MCASH_USDT</a>
                                                <a href='https://www.finexbox.com/market/pair/MCASH-USDT.html' target='_blank' rel="noreferrer">https://www.finexbox.com/market/pair/MCASH-USDT.html</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingFive">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseFive" aria-expanded="false"
                                                aria-controls="collapseFive">
                                                6. How can I register on the Platform?
                                            </button>
                                        </h2>
                                        <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body body-text pe-4">
                                                Prepare Mcash according to each plan in the wallet, go to the platform page, connect the wallet, press approve with the system and start mining.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingsix">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapsesix" aria-expanded="false"
                                                aria-controls="collapsesix">
                                                7. Can I Mine Forever without starting a Starter?
                                            </button>
                                        </h2>
                                        <div id="collapsesix" className="accordion-collapse collapse" aria-labelledby="headingsix"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body body-text pe-4">
                                                No, the system is forced to start at GN Starter first.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingseven">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseseven" aria-expanded="false"
                                                aria-controls="collapseseven">
                                                8. How many mine in one account?
                                            </button>
                                        </h2>
                                        <div id="collapseseven" className="accordion-collapse collapse" aria-labelledby="headingseven"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body body-text pe-4">
                                                One wallet can only do it once.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingeight">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseeight" aria-expanded="false"
                                                aria-controls="collapseeight">
                                                9. I want mining multiple wallet, can it be done?
                                            </button>
                                        </h2>
                                        <div id="collapseeight" className="accordion-collapse collapse" aria-labelledby="headingeight"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body body-text pe-4">
                                                Can do unlimited number of wallet
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingnine">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapsenine" aria-expanded="false"
                                                aria-controls="collapsenine">
                                                10. Can I pass on the inheritance to my family?
                                            </button>
                                        </h2>
                                        <div id="collapsenine" className="accordion-collapse collapse" aria-labelledby="headingnine"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body body-text pe-4">
                                                You can do Just keep collecting the Wallet application information well, don't lose it and tell this information to your heirs.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="fs-37 text-center">
                                Still have questions?
                            </div>
                            <p className="text-center">
                                If you can not find answer to your question <br />
                                you can mail on Coins2earn@gmail.com.
                            </p> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
