import React from "react";
import { useAccount } from 'wagmi';
import { useWeb3Modal } from '@web3modal/react';
import { trimAddress } from "../helper/constant";

export default function Connect(){
  const { open } = useWeb3Modal();
  const { address, isConnected } = useAccount();
  return (

    <button type="button" onClick={() => open()} className="cs-btn">
      {isConnected && address ? trimAddress(address) : "Connect Wallet"}
    </button>
  );
};