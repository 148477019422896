import moment from "moment";
import { bsc } from "wagmi/chains";

export const trimAddress = (addr) => {
  return `${addr.substring(0, 6) }...${addr.substring(addr.length - 4)}`;
}


export const contract = {
  56: {
    MULTICALL_ADDRESS: "0xca11bde05977b3631167028862be2a173976ca11",
    STAKE_ADDRESS: "0xe00179872575edb855ce5fa258c76661c54f0495",
    STAKE_ADDRESS2: "0x872330798840d744b0935dc68541e1a23343a0f0",
    TOKEN_ADDRESS : "0x154d91190b442f6eF880ED1bAEF6bd04E34659C2"
  },
  97: {
    MULTICALL_ADDRESS: "0xa54fe4a3dbd1eb21524cd73754666b7e13b4cb18",
    STAKE_ADDRESS: "0x925233b2751ad528b61ff08bcef096f02cfe48bc",
    STAKE_ADDRESS2: "0xcc62ac54dff5fe7e82f6a16a0aa371098c2edc8e",
    TOKEN_ADDRESS : "0x348E1E5E071A24CfBD20b58E9ccbFd0D92905b43"
  }
}

export const DEFAULT_CHAIN = 56;
export const TOKEN_DECIMALS = 8;
export const chains = [bsc]

export const formatDate = (unixTime, onlydate = false) => {
  try {
    if (onlydate) {
      let dateString = moment(unixTime).format("LL"); 
      return dateString;
    }
    else {
      let dateString = moment(unixTime).format('MMMM Do YYYY, h:mm:ss a');
      return dateString;
    }
  }
  catch (err) {
    console.log(err.message);
  }

}