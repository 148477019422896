import Web3 from "web3";
import { supportNetwork  } from './network';
import { DEFAULT_CHAIN } from "./constant";


export const infura_Id = "84842078b09946638c03157f83405213";

export const getRpcUrl = () => {
  return supportNetwork[DEFAULT_CHAIN].rpc;
}
export const getWeb3 = () =>{
  return new Web3(getRpcUrl());
}

