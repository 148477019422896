import { useEffect, useState } from "react"
import { getMultiCall, getStaking2Contract, getStakingContract } from "../helper/contractHelper";
import tokenAbi from '../json/token.json';
import { getWeb3 } from "../helper/connectors";
import { TOKEN_DECIMALS } from "../helper/constant";
import { useAccount, useNetwork } from "wagmi";



export const useHomeStats = (update) => {
  const [stats, setStats] = useState({
    totalSaving: 0,
    userCount: 0,
    totalSaving2: 0,
    userCount2: 0
  });

  const sc = getStakingContract()
  const sc2 = getStaking2Contract()

  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await getMultiCall([
          sc.methods.totalSaving(),
          sc.methods.totalUsers(),
          sc2.methods.totalSaving(),
          sc2.methods.totalUsers(),
        ]);

        setStats({
          totalSaving: data[0] / Math.pow(10, TOKEN_DECIMALS),
          userCount: data[1],
          totalSaving2: data[2] / Math.pow(10, TOKEN_DECIMALS),
          userCount2: data[3]
        });
      }
      catch (err) {
        console.log(err.message);
      }
    }


    fetch();


    // eslint-disable-next-line
  }, [update]);

  return stats;
}

export const useAccountStats = (updater) => {
  const { address } = useAccount();
  let web3 = getWeb3();
  const [stats, setStats] = useState({
    sPlan: false,
    sPlan_startTime: 0,
    sPlan_endTime: 0,
    sPlan_amount: 0,
    sPlanClaimed: false,
    fPlan: false,
    fPlan_amount: 0,
    fPlan_startTime: 0,
    fPlan_endTime: 0,
    totalClamied: 0,
    isApprove: 0,
    tokenBalance: 0,
    userPendingReward : 0
  });

  const sc = getStakingContract();

  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await getMultiCall([
          sc.methods.users(address),
          sc.methods.token(),
          sc.methods.userPendingReward(address)
        ]);


        let tc = new web3.eth.Contract(tokenAbi, data[1]);
        let tokenData = await getMultiCall([
          tc.methods.balanceOf(address),
          tc.methods.allowance(address, sc._address),
        ])

        

        setStats({
          sPlan: data[0][1],
          sPlan_startTime: data[0][2],
          sPlan_endTime: data[0][3],
          sPlan_amount: data[0][4] / Math.pow(10, TOKEN_DECIMALS),
          sPlanClaimed: data[0][5],
          fPlan: data[0][6],
          fPlan_amount: data[0][7],
          fPlan_startTime: data[0][8],
          fPlan_endTime: data[0][9],
          totalClamied: data[0][10] / Math.pow(10, TOKEN_DECIMALS),
          isApprove: parseInt(tokenData[1] / Math.pow(10, TOKEN_DECIMALS)),
          tokenBalance: tokenData[0] / Math.pow(10, TOKEN_DECIMALS),
          userPendingReward : data[2] / Math.pow(10, TOKEN_DECIMALS)
        })
      }
      catch (err) {
        console.log(err.message);
      }
    }

    if (address) {
      fetch();
    }
    else {
      setStats({
        sPlan: false,
        sPlan_startTime: 0,
        sPlan_endTime: 0,
        sPlan_amount: 0,
        sPlanClaimed: false,
        fPlan: false,
        fPlan_amount: 0,
        fPlan_startTime: 0,
        fPlan_endTime: 0,
        totalClamied: 0,
        isApprove: 0,
        tokenBalance: 0,
        userPendingReward : 0
      })
    }
    // eslint-disable-next-line
  }, [address, updater]);

  return stats;
}

export const useSPlanStats = (updater) => {
  const [stats, setStats] = useState({
    starterPlanInvest: 0,
    starter_plan: 0,
    withdrawFees: 0
  });

  const sc = getStakingContract()

  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await getMultiCall([
          sc.methods.starterPlanInvest(),
          sc.methods.starter_plan(),
          sc.methods.withdrawFees()
        ]);

        setStats({
          starterPlanInvest: data[0] / Math.pow(10, TOKEN_DECIMALS),
          starter_plan: data[1] / 86400,
          withdrawFees: data[2] / Math.pow(10, TOKEN_DECIMALS)
        });
      }
      catch (err) {
        console.log(err.message);
      }
    }


    fetch();


    // eslint-disable-next-line
  }, [updater]);

  return stats;
}


export const useFPlanStats = (updater) => {
  const [stats, setStats] = useState({
    foreverPlanInvest: 0,
    forever_plan: 0,
    claimFees: 0,
    rewardRate : 0
  });

  const sc = getStakingContract()

  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await getMultiCall([
          sc.methods.foreverPlanInvest(),
          sc.methods.forever_plan(),
          sc.methods.claimFees(),
          sc.methods.rewardRate()
        ]);

        setStats({
          foreverPlanInvest: data[0] / Math.pow(10, TOKEN_DECIMALS),
          forever_plan: data[1] / 86400,
          claimFees: data[2] / Math.pow(10, TOKEN_DECIMALS),
          rewardRate : data[3] / Math.pow(10, TOKEN_DECIMALS)
        });
      }
      catch (err) {
        console.log(err.message);
      }
    }


    fetch();


    // eslint-disable-next-line
  }, [updater]);

  return stats;
}

//premimum


export const useAccountPremiumStats = (updater) => {
  const { address } = useAccount();
  let web3 = getWeb3();
  const [stats, setStats] = useState({
    sPlan: false,
    sPlan_startTime: 0,
    sPlan_endTime: 0,
    sPlan_amount: 0,
    sPlanClaimed: false,
    fPlan: false,
    fPlan_amount: 0,
    fPlan_startTime: 0,
    fPlan_endTime: 0,
    totalClamied: 0,
    isApprove: 0,
    tokenBalance: 0,
    userPendingReward : 0
  });

  const sc = getStaking2Contract();

  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await getMultiCall([
          sc.methods.users(address),
          sc.methods.token(),
          sc.methods.userPendingReward(address)
        ]);


        let tc = new web3.eth.Contract(tokenAbi, data[1]);
        let tokenData = await getMultiCall([
          tc.methods.balanceOf(address),
          tc.methods.allowance(address, sc._address),
        ])

        

        setStats({
          sPlan: data[0][1],
          sPlan_startTime: data[0][2],
          sPlan_endTime: data[0][3],
          sPlan_amount: data[0][4] / Math.pow(10, TOKEN_DECIMALS),
          sPlanClaimed: data[0][5],
          fPlan: data[0][6],
          fPlan_amount: data[0][7],
          fPlan_startTime: data[0][8],
          fPlan_endTime: data[0][9],
          totalClamied: data[0][10] / Math.pow(10, TOKEN_DECIMALS),
          isApprove: parseInt(tokenData[1] / Math.pow(10, TOKEN_DECIMALS)),
          tokenBalance: tokenData[0] / Math.pow(10, TOKEN_DECIMALS),
          userPendingReward : data[2] / Math.pow(10, TOKEN_DECIMALS)
        })
      }
      catch (err) {
        console.log(err.message);
      }
    }

    if (address) {
      fetch();
    }
    else {
      setStats({
        sPlan: false,
        sPlan_startTime: 0,
        sPlan_endTime: 0,
        sPlan_amount: 0,
        sPlanClaimed: false,
        fPlan: false,
        fPlan_amount: 0,
        fPlan_startTime: 0,
        fPlan_endTime: 0,
        totalClamied: 0,
        isApprove: 0,
        tokenBalance: 0,
        userPendingReward : 0
      })
    }
    // eslint-disable-next-line
  }, [address, updater]);

  return stats;
}


export const useSPlanPremimumStats = (updater) => {
  const [stats, setStats] = useState({
    starterPlanInvest: 0,
    starter_plan: 0,
    withdrawFees: 0
  });

  const sc = getStaking2Contract()

  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await getMultiCall([
          sc.methods.starterPlanInvest(),
          sc.methods.starter_plan(),
          sc.methods.withdrawFees()
        ]);

        setStats({
          starterPlanInvest: data[0] / Math.pow(10, TOKEN_DECIMALS),
          starter_plan: data[1] / 86400,
          withdrawFees: data[2] / Math.pow(10, TOKEN_DECIMALS)
        });
      }
      catch (err) {
        console.log(err.message);
      }
    }


    fetch();


    // eslint-disable-next-line
  }, [updater]);

  return stats;
}


export const useFPlanPremimumStats = (updater) => {
  const [stats, setStats] = useState({
    foreverPlanInvest: 0,
    forever_plan: 0,
    claimFees: 0,
    rewardRate : 0
  });

  const sc = getStaking2Contract()

  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await getMultiCall([
          sc.methods.foreverPlanInvest(),
          sc.methods.forever_plan(),
          sc.methods.claimFees(),
          sc.methods.rewardRate()
        ]);

        setStats({
          foreverPlanInvest: data[0] / Math.pow(10, TOKEN_DECIMALS),
          forever_plan: data[1] / 86400,
          claimFees: data[2] / Math.pow(10, TOKEN_DECIMALS),
          rewardRate : data[3] / Math.pow(10, TOKEN_DECIMALS)
        });
      }
      catch (err) {
        console.log(err.message);
      }
    }


    fetch();


    // eslint-disable-next-line
  }, [updater]);

  return stats;
}




