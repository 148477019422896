import binanceCoin from '../images/binance-coin.svg';


export const supportNetwork = { 
    56 : {
        name : "BINANCE",
        chainId : 56,
        rpc : "https://bsc-dataseed.binance.org/",
        image : binanceCoin
    },
    // 97 : {
    //     name : "TBINANCE",
    //     chainId : 97,
    //     rpc : "https://data-seed-prebsc-1-s3.binance.org:8545/",
    //     image : binanceCoin
    // }
}

