import {
    EthereumClient,
    w3mConnectors,
    w3mProvider,
  } from "@web3modal/ethereum";
  
  import { configureChains, createClient, WagmiConfig } from "wagmi";
  import { chains } from "./constant";
  import { Web3Modal } from "@web3modal/react";
  
  
  const projectId = process.env.REACT_APP_PROJECT_ID
  // Wagmi client
  const { provider } = configureChains(chains, [
    w3mProvider({ projectId }),
  ]);
  
  export const wagmiClient = createClient({
    autoConnect: true,
    connectors: w3mConnectors({ projectId, chains }),
    provider,
  });
  
  // Web3Modal Ethereum Client
  export const ethereumClient = new EthereumClient(wagmiClient, chains);
  
  export const Web3Provider = ({ children }) => {
    return (
      <WagmiConfig client={wagmiClient}>
        {children}
        <Web3Modal projectId={process.env.REACT_APP_PROJECT_ID} ethereumClient={ethereumClient} themeVariables={{
          '--w3m-accent-color': `#${process.env.REACT_APP_CONNECT_MODAL_COLOR_CODE}`,
          '--w3m-background-color': `#${process.env.REACT_APP_CONNECT_MODAL_COLOR_CODE}`,
  
        }} />
      </WagmiConfig>
    );
  };