import React, { useState } from 'react';
import { formatPrice } from '../helper/contractHelper';
import { useAccountPremiumStats, useAccountStats, useHomeStats } from '../hooks/useStats';
import StarterPlan from './StarterPlan';
import ForeverPlan from './ForeverPlan';
import StarterPlanPremimum from './StarterPlanPremimum';
import ForeverPlanPremimum from './ForeverPlanPremimum';



export default function Saving() {

    const daysTab = {
        links: [
            {
                id: 1,
                className: "StakeTablinks",
                content: "GN Starter",
                apy: "(Lock 365 Days)",
                c_id: 0
            },
            {
                id: 2,
                className: "StakeTablinks",
                content: "GN Forever",
                apy: "(Income 30 Years)",
                c_id: 1
            },
            {
                id: 3,
                className: "StakeTablinks",
                content: "GN Premium Starter",
                apy: "(Lock 365 Days)",
                c_id: 2
            },
            {
                id: 4,
                className: "StakeTablinks",
                content: "GN Premium Forever",
                apy: "(Income 30 Years)",
                c_id: 3
            }
        ]
    };

    const [dayId, setDayId] = useState(1);
    const [updater, setUpdater] = useState(1);
    const stats = useHomeStats(updater);
    const accStats = useAccountStats(updater);
    const accStatsp = useAccountPremiumStats(updater);
    const oldPrice = parseFloat(parseFloat(stats.userCount) * parseFloat(0.00025)).toFixed(5);
    const newPrice = parseFloat(parseFloat(stats.userCount2) * parseFloat(0.0025)).toFixed(5);
    const totalMGPPrice = parseFloat(oldPrice) + parseFloat(newPrice) + parseInt(35);

    const handleChangeDay = (id) => {
        setDayId(id);
    }

    return (
        <div className="staking-overflow py-4 py-sm-5">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link fs-21 active" id="pills-staking-overflow-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-staking-overflow" type="button" role="tab"
                                    aria-controls="pills-staking-overflow"
                                    aria-selected="true">Mining
                                </button>
                            </li>
                        </ul>
                        <div className="tab-content rounded-8 mt-4 mt-sm-5" id="pills-tabContent">
                            {/* <!--STAKING-OVERFLOW--> */}
                            <div className="tab-pane fade show active" id="pills-staking-overflow" role="tabpanel"
                                aria-labelledby="pills-staking-overflow-tab">
                                <div className="row gx-4 gy-3 gy-sm-0 gx-sm-5 mb-4 mb-sm-5 ">
                                    <div className="col-sm-4">
                                        <div className="top-content d-flex flex-column gap-3 text-dark justify-content-center align-items-center">
                                            <p>MGP Price Per 1 MMSC</p>
                                            <div className="fs-28 fw-bold">{formatPrice(totalMGPPrice)}</div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="top-content d-flex flex-column gap-3 text-dark justify-content-center align-items-center">
                                            <p>{dayId === 1 || dayId === 2 ? 'Total Starter User' : 'Total Premium User'}</p>
                                            <div className="fs-28 fw-bold">
                                                {dayId === 1 || dayId === 2 ? (
                                                    stats.userCount ? formatPrice(parseFloat(stats.userCount)) : 0
                                                ) : (
                                                    stats.userCount2 ? formatPrice(parseFloat(stats.userCount2)) : 0
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="top-content d-flex flex-column gap-3 text-dark justify-content-center align-items-center">
                                            <p>{dayId === 1 || dayId === 2 ? 'Total Starter Mining' : 'Total Premium Mining'}</p>
                                            <div className="fs-28 fw-bold">
                                                {dayId === 1 || dayId === 2 ? (
                                                    stats.totalSaving ? formatPrice(parseFloat(stats.totalSaving)) : 0
                                                ) : (
                                                    stats.totalSaving2 ? formatPrice(parseFloat(stats.totalSaving2)) : 0
                                                )}

                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="row mb-100">
                                    <div className="col-12">
                                        <div className="fs-28 mb-sm-5 mb-4">Mining Submission</div>
                                        <div className="d-flex staking-days flex-wrap flex-lg-nowrap text-center">
                                            {daysTab.links.map(link => {
                                                return (
                                                    <div key={link.id} onClick={(e) => { handleChangeDay(link.id) }} className={`${link.id === dayId ? " active" : ""} align-items-center w-100 d-flex flex-column item justify-content-center rounded-8 position-relative overflow-hidden`}>
                                                        <div className="fs-21">{link.content}</div>
                                                        {/* <p className='fs-21'>{link.apy}</p> */}
                                                        {dayId === link.id &&
                                                            <i className="fa-solid fa-check text-white position-absolute"
                                                            ></i>
                                                        }
                                                    </div>

                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                </div>

                                {dayId === 1 ?
                                    (
                                        <StarterPlan updater={updater} setUpdater={setUpdater} accStats={accStats} />
                                    ) : dayId === 2 ? (
                                        <ForeverPlan updater={updater} setUpdater={setUpdater} accStats={accStats} />
                                    ) : dayId === 3 ? (
                                        <StarterPlanPremimum updater={updater} setUpdater={setUpdater} accStats={accStatsp} />
                                    ) : (
                                        <ForeverPlanPremimum updater={updater} setUpdater={setUpdater} accStats={accStatsp} />
                                    )
                                }


                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
