import React , {useState} from 'react';
import { supportNetwork } from '../helper/network';
import { DEFAULT_CHAIN } from '../helper/constant';

export default function NetworkMenu() {
    const [networkshow, setNetworkshow] = useState(false);

    const switchNetwork = (networkid) => {
        try {
            // @ts-ignore
            window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: `0x${networkid.toString(16)}` }]
            });
        } catch (e) {
            console.error(e.message);
        }
    }

    return (
        <React.Fragment>
            <div className="network on-mobile mr-3" style={{ cursor: "pointer" }} onClick={()=>switchNetwork(DEFAULT_CHAIN)}>

                <img width={18} src={`${supportNetwork[DEFAULT_CHAIN].image}`} alt="Switch Network" className="mx-2" />
                <span className="ml-2 hide-on-mobile">BSC MAINNET</span>

            </div>

        </React.Fragment>
    )
}
